import React, { Component } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { isEmpty, difference } from "lodash";
import { Helmet } from "react-helmet";

import actions from "../../ducks/actions";
import selectors from "../../ducks/selectors";

import CATEGORIES from "../Utils/constants";

// Icons
import HomeIcon from "@material-ui/icons/Home";
import TrendingIcon from "@material-ui/icons/TrendingUp";
import SubscriptionsIcon from "@material-ui/icons/CollectionsBookmark";
import LibraryIcon from "@material-ui/icons/LocalLibrary";
import SearchIcon from "@material-ui/icons/Search";
import FilterIcon from "@material-ui/icons/FilterList";

// Components
import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common/IconButton/IconButton";
import Logo from "../common/Logo/Logo";
import MainBottomNavBar from "../common/BottomNavBar/MainBottomNavBar";
import HomePage from "../HomePage/HomePage";
import GroupsPage from "../GroupScreens/GroupsPage";
import TrendingPage from "../TrendingPage/TrendingPage";
import SubscriptionsPage from "../SubscriptionsPage/SubscriptionsPage";
import LibraryContainer from "../LibraryPage/LibraryContainer";
import WarningDialog from "../common/WarningDialog";
// CSS
import "./HomeContainer.css";

class Home extends Component {
  state = {
    navigationValue: null,
    navigationItems: [
      {
        label: "Home",
        icon: <HomeIcon />,
      },
      {
        label: "Trending",
        icon: <TrendingIcon />,
      },
      {
        label: "Following",
        icon: <SubscriptionsIcon />,
      },
      {
        label: "Library",
        icon: <LibraryIcon />,
      },
    ],
    uniquePreferences: false,
  };

  componentDidMount() {
    const { history, userInfo } = this.props;
    if (
      userInfo &&
      isEmpty(userInfo.interests) &&
      userInfo.lastActiveAt === null
    ) {
      history.push({
        pathname: "/settings/preferences",
        state: { from: this.props.location.pathname },
      });
    }
    this.checkUniquePreferences();

    this.checkUrlForSignInDialog();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (prevProps.location.pathname !== location.pathname) {
      this.checkUrlForSignInDialog();
    }
  }

  checkUrlForSignInDialog = () => {
    const { userInfo, location, onSignInUpDialogOpening } = this.props;

    if (
      !userInfo &&
      (location.pathname === "/subscriptions" ||
        location.pathname.includes("/library"))
    ) {
      onSignInUpDialogOpening("feature");
    }
  };

  centerButtonClick = () => {
    const { history } = this.props;
    if (!this.props.userInfo) {
      this.props.onHamburgerClick();
    } else {
      history.push("/landing-page");
      window.scrollTo(0, 0);
    }
  };

  rightButtonClick = () => {
    const {
      onSearchDialogOpening,
      fireSearchWithoutEmptyQuery,
      removeAllInterests,
      history,
      setSearchQueryGroup,
      clearGroupListItemsCToken,
    } = this.props;

    onSearchDialogOpening();
    fireSearchWithoutEmptyQuery();
    removeAllInterests();

    setSearchQueryGroup("");
    clearGroupListItemsCToken();

    history.push({
      pathname: "/search",
      state: { from: this.props.history.location.pathname },
    });
  };

  handleAvatarClick = () => {
    if (!this.props.userInfo) {
      this.props.history.push({ pathname: "/sign-in-up" });
    } else {
      this.props.onHamburgerClick();
    }
  };

  rightSecondaryButtonClick = () => {
    this.props.history.push({
      pathname: "/filter-interests",
      state: { from: this.props.history.location.pathname },
    });
  };

  checkUniquePreferences = () => {
    const { userInfo } = this.props;
    if (
      userInfo &&
      (userInfo.publishDate !== "all" ||
        difference(
          ["me", "collaborations", "groups", "subscriptions", "everyone_else"],
          userInfo.filterBy
        ).length > 0 ||
        (userInfo.interests.length > 0 &&
          difference(Object.keys(CATEGORIES), userInfo.interests).length > 0))
    ) {
      this.setState({ uniquePreferences: true });
    } else {
      this.setState({ uniquePreferences: false });
    }
  };

  render() {
    const { ...props } = this.props;
    return (
      <div>
        <Helmet>
          <title>{process.env.REACT_APP_TITLE}</title>
        </Helmet>
        <TopBar
          center={<Logo />}
          rightSecondary={
            props.history.location.pathname === "/home" ||
            props.history.location.pathname === "/trending" ? (
              <IconButton icon={<FilterIcon />} />
            ) : null
          }
          right={<IconButton icon={<SearchIcon />} />}
          handleLeftButtonClick={props.onHamburgerClick}
          handleCenterButtonClick={this.centerButtonClick}
          handleRightButtonClick={this.rightButtonClick}
          handleRightSecondaryButtonClick={this.rightSecondaryButtonClick}
          handleAvatarClick={this.handleAvatarClick}
          uniquePreferences={
            this.state.uniquePreferences &&
            props.history.location.pathname === "/home"
              ? true
              : false
          }
          homepage={true}
          history={props.history}
        />
        <div className="main-container">
          <Route exact path="/home" component={HomePage} />
          <Route path="/groups" component={GroupsPage} />
          <Route exact path="/trending" component={TrendingPage} />
          <Route exact path="/subscriptions" component={SubscriptionsPage} />
          <Route path="/library" component={LibraryContainer} />
        </div>
        <MainBottomNavBar />
        {/* //! This redux state has to be addressed, already planned but needs to be discussed */}
        {props.warningDialog.type.tourtleId !==
          "cancel-add-tourtle-to-group" && (
          <WarningDialog
            type="sharePrivate"
            message="This tourtle is marked Private and so cannot be shared. Only Public and Unlisted tourtles can be shared. Or, you can share it selectively by adding it to a Group where only its members can see it."
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userInfo: selectors.getCurrentUserInfo(state),
  signInUpDialog: selectors.getSignInUpDialog(state),
  warningDialog: selectors.getWarningDialog(state),
});

const mapDispatchToProps = {
  onHamburgerClick: actions.fireLeftNavOpening,
  onSignInUpDialogOpening: actions.fireSignInUpDialogOpening,
  onSearchDialogOpening: actions.fireSearchDialogOpening,
  fireSearchWithoutEmptyQuery: actions.fireSearchWithoutEmptyQuery,
  removeAllInterests: actions.removeAllInterests,
  onFireLoginDialogOpening: actions.fireLoginDialogOpening,
  setGroupTabValue: actions.setGroupTabValue,

  setSearchQueryGroup: actions.setSearchQueryGroup,
  clearGroupListItemsCToken: actions.clearGroupListItemsCToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
