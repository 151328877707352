import React, { Component } from "react";
import { connect } from "react-redux";

// Material UI
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import HelpIcon from "@material-ui/icons/Help";
import AdminIcon from "@material-ui/icons/Build";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

// CSS
import "./TopBar.css";
import {
  Divider,
  Fade,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
} from "@material-ui/core";
import {
  AccountBox,
  Block,
  ChevronRight,
  Feedback,
  ListAlt,
} from "@material-ui/icons";
import CorporateFare from "@material-ui/icons/ImportContacts";
import SettingsIcon from "@material-ui/icons/Settings";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      open: false,
      id: undefined,
    };

    this.handleHoverMenuOpen = this.handleHoverMenuOpen.bind(this);
    this.handleHoverMenuClose = this.handleHoverMenuClose.bind(this);
  }

  getClassNameCenter = () => {
    if (this.props.centerType === "search") {
      return 6;
    } else if (this.props.rightSecondary) {
      return 4;
    }
    return 10;
  };

  getClassNameLeft = () => {
    if (this.props.centerType === "search") {
      return 2;
    } else if (this.props.rightSecondary) {
      return 3;
    }
    return "auto";
  };

  goToAccount = () => {
    const { history } = this.props;
    this.handleHoverMenuClose();
    history.push("/settings/profile");
  };

  // link to help
  goToHelp = () => {
    const { history } = this.props;
    this.handleHoverMenuClose();
    history.push({
      pathname:
        window.location.host.includes("staging") ||
        window.location.host.includes("localhost")
          ? "/tourtle/6335e648a1d2f74a2eaba01e/tourtle-help"
          : "/tourtle/6324ced1bc206b381fe4b6da/tourtle-help-and-guidelines",
    });
  };

  // link to feedback page
  goToSendFeedback = () => {
    window.location.href =
      "mailto:feedback@tourtle.com?subject=Tourtler%20feedback";
  };

  // link to admin settings page
  goToAdmin = () => {
    const { history } = this.props;
    this.handleHoverMenuClose();
    history.push(`/admin`);
  };

  // link to settings page
  goToSettings = () => {
    const { history } = this.props;
    this.handleHoverMenuClose();
    history.push(`/settings`);
  };

  // link to policies tourtle
  goToPolicies = () => {
    const { history } = this.props;
    this.handleHoverMenuClose();
    history.push({
      pathname:
        window.location.host.includes("staging") ||
        window.location.host.includes("localhost")
          ? "/tourtle/6335c021a1d2f76caeab9fec/policies"
          : "/tourtle/632ceb4fbc206b9de3e4b7fb/policies",
    });
  };

  // link to about tourtle
  goToAboutTourtle = () => {
    const { history } = this.props;
    this.handleHoverMenuClose();
    history.push({
      pathname:
        window.location.host.includes("staging") ||
        window.location.host.includes("localhost")
          ? "/tourtle/6335e876a1d2f74659aba023/tourtle-corporate-information"
          : "/tourtle/5d363e89141fc957f7f8d889/tourtle-corporate-information",
    });
  };

  // sign out
  handleSignOut = () => {
    const { onSignOutDialogOpening } = this.props;
    this.handleHoverMenuClose();
    onSignOutDialogOpening();
  };

  handleHoverMenuOpen = event => {
    if (this.state.open === true) {
      return;
    }
    this.setState({
      anchorEl: event.currentTarget,
      open: Boolean(event.currentTarget),
    });
  };

  handleHoverMenuClose = () => {
    this.setState({
      anchorEl: null,
      open: false,
    });
  };

  handleAvatarClick = () => {
    const { handleAvatarClick } = this.props;
    if (this.state.open) {
      this.handleHoverMenuClose();
    }
    handleAvatarClick();
  };

  render() {
    const { history, ...props } = this.props;
    return (
      <div>
        <AppBar
          position="fixed"
          className={`app-bar ${this.props.bottom ? "bottom" : ""}`}
        >
          <Toolbar className="tool-bar">
            <Grid container justify="center">
              <Grid
                name="spacing"
                aria-label="Spacing"
                item
                xs={12}
                sm={8}
                md={6}
                lg={4}
                // className="tool-bar-inner"
              >
                {/* LEFT ICON */}
                <Grid container>
                  <Grid
                    lg={props.homepage ? false : props.overlappingTitle ? 3 : 1}
                    sm={props.homepage ? false : props.overlappingTitle ? 3 : 1}
                    xs={props.homepage ? false : props.overlappingTitle ? 3 : 1}
                    md={props.homepage ? false : props.overlappingTitle ? 3 : 1}
                    item
                    // className="left-icon-wrap"
                  >
                    <div onClick={props.handleLeftButtonClick} className="left">
                      {props.left}
                    </div>
                  </Grid>

                  {/* SPACER */}
                  <Grid
                    lg={
                      this.props.centerType === "search"
                        ? 1
                        : props.homepage
                        ? false
                        : this.props.rightSecondary
                        ? 3
                        : "auto"
                    }
                    sm={
                      this.props.centerType === "search"
                        ? 1
                        : props.homepage
                        ? false
                        : this.props.rightSecondary
                        ? 3
                        : "auto"
                    }
                    xs={
                      this.props.centerType === "search"
                        ? 1
                        : props.homepage
                        ? false
                        : this.props.rightSecondary
                        ? 3
                        : "auto"
                    }
                    md={
                      this.props.centerType === "search"
                        ? 1
                        : props.homepage
                        ? false
                        : this.props.rightSecondary
                        ? 3
                        : "auto"
                    }
                    // className="spacer-wrap"
                    item
                  />

                  {/* CENTER */}
                  {/* <Grid item className="top-bar-center-wrap"> */}
                  <Grid
                    lg={
                      props.homepage && this.props.rightSecondary
                        ? 6
                        : this.props.centerType === "search"
                        ? 8
                        : this.props.rightSecondary
                        ? 4
                        : this.props.homepage
                        ? 8
                        : props.overlappingTitle
                        ? 6
                        : props.wide
                        ? 9
                        : 10
                    }
                    sm={
                      props.homepage && this.props.rightSecondary
                        ? 6
                        : this.props.centerType === "search"
                        ? 7
                        : this.props.rightSecondary
                        ? 4
                        : this.props.homepage
                        ? 8
                        : props.overlappingTitle
                        ? 6
                        : props.wide
                        ? 9
                        : 10
                    }
                    xs={
                      props.homepage && this.props.rightSecondary
                        ? 6
                        : this.props.centerType === "search"
                        ? 7
                        : this.props.rightSecondary
                        ? 4
                        : this.props.homepage
                        ? 8
                        : props.overlappingTitle
                        ? 6
                        : props.wide
                        ? 9
                        : 10
                    }
                    md={
                      props.homepage && this.props.rightSecondary
                        ? 6
                        : this.props.centerType === "search"
                        ? 8
                        : this.props.rightSecondary
                        ? 4
                        : this.props.homepage
                        ? 8
                        : props.overlappingTitle
                        ? 6
                        : props.wide
                        ? 9
                        : 10
                    }
                    item
                  >
                    <div
                      onClick={props.handleCenterButtonClick}
                      onDoubleClick={props.handleCenterButtonDoubleClick}
                      className={
                        props.addTourtlesToGroup
                          ? "top-bar-center"
                          : "top-bar-center-text"
                      }
                    >
                      {props.center}
                    </div>
                  </Grid>

                  {/* SPACER */}
                  {!props.rightSecondary && (
                    <Grid
                      lg={props.centerType === "search" ? 1 : "auto"}
                      sm={props.centerType === "search" ? 2 : "auto"}
                      xs={props.centerType === "search" ? 2 : "auto"}
                      md={props.centerType === "search" ? 1 : "auto"}
                      item
                      // className="spacer-secondary"
                    />
                  )}

                  {/* RIGHT ICON */}
                  {/* <Grid className="right-icons" item> */}
                  <Grid
                    lg={
                      props.homepage && props.rightSecondary
                        ? 6
                        : props.overlappingTitle
                        ? 3
                        : props.rightSecondary
                        ? 4
                        : props.homepage
                        ? 4
                        : props.wide
                        ? 2
                        : 1
                    }
                    sm={
                      props.homepage && props.rightSecondary
                        ? 6
                        : props.overlappingTitle
                        ? 3
                        : props.rightSecondary
                        ? 4
                        : props.homepage
                        ? 4
                        : props.wide
                        ? 2
                        : 1
                    }
                    xs={
                      props.homepage && props.rightSecondary
                        ? 6
                        : props.overlappingTitle
                        ? 3
                        : props.rightSecondary
                        ? 4
                        : props.homepage
                        ? 4
                        : props.wide
                        ? 2
                        : 1
                    }
                    md={
                      props.homepage && props.rightSecondary
                        ? 6
                        : props.overlappingTitle
                        ? 3
                        : props.rightSecondary
                        ? 4
                        : props.homepage
                        ? 4
                        : props.wide
                        ? 2
                        : 1
                    }
                    item
                  >
                    {props.homepage &&
                      props.userInfo &&
                      !props.userInfo.avatar && (
                        <div className="top-avatar-container">
                          <Avatar
                            onClick={this.handleAvatarClick}
                            onMouseEnter={this.handleHoverMenuOpen}
                            className="top-avatar"
                          >
                            {props.userInfo.username &&
                              props.userInfo.username.charAt(0).toUpperCase()}
                          </Avatar>
                        </div>
                      )}
                    {props.homepage && props.userInfo && props.userInfo.avatar && (
                      <div className="top-avatar-container">
                        <Avatar
                          alt={props.userInfo.username}
                          className="top-avatar"
                          src={props.userInfo.avatar}
                          onClick={this.handleAvatarClick}
                          onMouseEnter={this.handleHoverMenuOpen}
                        />
                      </div>
                    )}
                    {props.homepage && !props.userInfo && (
                      <div
                        className="sign-in-icon"
                        onClick={this.handleAvatarClick}
                      >
                        <Typography className="sign-in-text">
                          {"Sign\nup/in"}
                        </Typography>
                      </div>
                    )}
                    <div
                      onClick={props.handleRightButtonClick}
                      className={
                        !props.rightSecondary &&
                        (props.centerType === "search" ||
                          props.centerType === "filter") &&
                        props.uniquePreferences === true
                          ? "right-green"
                          : !props.rightSecondary &&
                            (props.centerType === "search" ||
                              props.centerType === "filter") &&
                            props.uniquePreferences === false
                          ? "right-moved"
                          : props.centerType === "group-page"
                          ? "right-moved"
                          : "right"
                      }
                    >
                      {props.right}
                    </div>
                    {props.rightSecondary && (
                      <div
                        onClick={props.handleRightSecondaryButtonClick}
                        className={
                          props.uniquePreferences
                            ? "secondary-right-green"
                            : "secondary-right"
                        }
                      >
                        {props.rightSecondary}
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {/* menu */}
        <Popper
          id="account-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          placement="bottom-end"
          style={{ zIndex: 1000 }}
          onMouseLeave={this.handleHoverMenuClose}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Paper>
                {props.userInfo && !props.userInfo.avatar && (
                  <MenuItem onClick={this.goToAccount} selected={false}>
                    <ListItemIcon>
                      <Avatar alt={props.userInfo.username}>
                        {props.userInfo.username &&
                          props.userInfo.username.charAt(0).toUpperCase()}
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText primary={props.userInfo.username} />
                    <ChevronRight />
                  </MenuItem>
                )}

                {props.userInfo && props.userInfo.avatar && (
                  <MenuItem onClick={this.goToAccount} selected={false}>
                    <ListItemIcon>
                      <Avatar
                        alt={props.userInfo.username}
                        src={props.userInfo.avatar}
                      />
                    </ListItemIcon>
                    <ListItemText primary={props.userInfo.username} />
                    <ChevronRight />
                  </MenuItem>
                )}

                <Divider />
                <List>
                  {props.userInfo && (
                    <MenuItem onClick={this.goToAccount}>
                      <ListItemIcon>
                        <AccountBox />
                      </ListItemIcon>
                      <ListItemText primary="Profile" />
                    </MenuItem>
                  )}
                  <MenuItem onClick={this.goToSettings}>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                  </MenuItem>
                </List>
                <List className="dropdown-sublist">
                  {this.props.userInfo && this.props.userInfo.isAdmin && (
                    <MenuItem onClick={this.goToAdmin}>
                      <ListItemIcon>
                        <AdminIcon />
                      </ListItemIcon>
                      <ListItemText primary="Admin" />
                    </MenuItem>
                  )}
                  <MenuItem onClick={this.goToAboutTourtle}>
                    <ListItemIcon>
                      <CorporateFare />
                    </ListItemIcon>
                    <ListItemText primary="About tourtle" />
                  </MenuItem>
                  <MenuItem onClick={this.goToHelp}>
                    <ListItemIcon>
                      <HelpIcon />
                    </ListItemIcon>
                    <ListItemText primary="Help &amp; Guidelines" />
                  </MenuItem>
                  <MenuItem onClick={this.goToPolicies}>
                    <ListItemIcon>
                      <ListAlt />
                    </ListItemIcon>
                    <ListItemText primary="Policies" />
                  </MenuItem>
                  <MenuItem onClick={this.goToSendFeedback}>
                    <ListItemIcon>
                      <Feedback />
                    </ListItemIcon>
                    <ListItemText primary="Provide Feedback" />
                  </MenuItem>
                  <MenuItem onClick={this.handleSignOut}>
                    <ListItemIcon>
                      <Block />
                    </ListItemIcon>
                    <ListItemText primary="Sign Out" />
                  </MenuItem>
                </List>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userInfo: selectors.getCurrentUserInfo(state),
});
const mapDispatchToProps = {
  onSignOutDialogOpening: actions.fireSignOutOpening,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
