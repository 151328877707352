import "react-app-polyfill/ie9";
import React from "react";
import { render } from "react-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import Root from "./components/Root";

import { store, history } from "./store";

if (window.Cypress) {
  window.store = store;
}

// const requireLogin = (indexState, replace) => {
//   const {auth: {authenticated}} = store.getState();
//   if (!authenticated) {
//     const destinationPath = indexState.location.pathname;
//     replace({
//       pathname: '/login',
//       query: {destinationPath}
//     });
//   }
// };

render(
  <React.Fragment>
    <CssBaseline />
    <Root store={store} history={history} />
  </React.Fragment>,
  document.getElementById("root")
);
